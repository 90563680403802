import { DeviceHealth } from "./device-health.model";

export class Device {
  altitude: number;
  companyId: string;
  deviceType: string;
  id: string;
  isOnline: boolean;
  lastConnected: Date;
  lastUpdated: Date;
  latitude: number;
  longitude: number;
  serialNumber: string;
  displayName: string;
  health: DeviceHealth;
  hidden: boolean;
  compliant: string;
  complianceStatus: string;
  aircraftId: string;
}
