import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import Swal from "sweetalert2";
import { ApplicationInsightsService } from "../application-insights.service";
import { EnvService } from "../env.service";

export class CreateUser {
  static readonly type = "[User] Create";

  constructor(public id: string, public name: string, public username: string, public role: string) {}
}

export class SaveUser {
  static readonly type = "[User] Save";

  constructor(public id: string, public role: string, public claims: string) {}
}

export class DeleteUser {
  static readonly type = "[User] Delete";

  constructor(public id: string) {}
}

export class UpdateUserList {
  static readonly type = "[User] Update List";
}

@Injectable()
@State({
  name: "user",
})
export class UserState {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvService,
    private readonly appInsights: ApplicationInsightsService
  ) {}

  @Action(CreateUser)
  createUser(ctx: StateContext<any>, action: CreateUser) {
    this.appInsights.trackEvent({
      name: "User create",
      properties: {
        role: action.role,
      },
    });

    return this.http
      .post(`${this.envService.apiUri}/api/v1/users`, {
        userAzureId: action.id,
        userName: action.username,
        fullName: action.name,
        role: action.role,
      })
      .pipe(
        tap(() => {
          Swal.fire({
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            title: "User has been created.",
          });

          ctx.dispatch(new UpdateUserList());
        })
      );
  }

  @Action(SaveUser)
  saveUser(ctx: StateContext<any>, action: SaveUser) {
    this.appInsights.trackEvent({
      name: "User update",
      properties: {
        role: action.role,
        claims: action.claims,
      },
    });

    return this.http
      .put(`${this.envService.apiUri}/api/v1/users/${action.id}/setRole`, {
        userId: action.id,
        role: action.role,
        claims: action.claims === "" ? null : action.claims,
      })
      .pipe(
        tap(() => {
          Swal.fire({
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            title: "User has been updated.",
          });

          ctx.dispatch(new UpdateUserList());
        })
      );
  }

  @Action(DeleteUser)
  deleteUser(ctx: StateContext<any>, action: DeleteUser) {
    this.appInsights.trackEvent({
      name: "User delete",
    });

    return this.http.delete(`${this.envService.apiUri}/api/v1/users/${action.id}`).pipe(
      tap(() => {
        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          title: "User has been deleted.",
        });

        ctx.dispatch(new UpdateUserList());
      })
    );
  }
}
