import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import Swal from "sweetalert2";
import { EnvService } from "../env.service";

export class SetDefaultAccess {
  static readonly type = "[Company] Set Default Access";

  constructor(public role: string) {}
}

@Injectable()
@State({
  name: "company",
})
export class CompanyState {
  constructor(private readonly http: HttpClient, private readonly envService: EnvService) {}

  @Action(SetDefaultAccess)
  createUser(ctx: StateContext<any>, action: SetDefaultAccess) {
    return this.http.put(`${this.envService.apiUri}/api/v1/company/setDefaultAccess/${action.role}`, null).pipe(
      tap(() => {
        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          title: "Settings have been saved.",
        });
      })
    );
  }
  /*
  @Action(FetchCompany)
  fetchDevice(ctx: StateContext<CompanyStateModel>, action: FetchCompany) {
    return this.http
      .get<Company>(`${this.envService.apiUri}/api/v1/company/${action.companyId}`)
      .pipe(tap(company => ctx.setState(patch({ data: insertItem<Company>(Object.assign(new Company(), company)) }))));
  }*/
}
