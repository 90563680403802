import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { BasicNotification } from "src/app/shared/models/basic-notification.model";
import { ApplicationInsightsService } from "../application-insights.service";
import { EnvService } from "../env.service";
import { catchError, tap } from "rxjs/operators";
import { insertItem, patch, removeItem, updateItem } from "@ngxs/store/operators";
import * as _ from "underscore";
import { BasicNotificationHolder } from "src/app/shared/models/basic-notification-holder.model";
import Swal from "sweetalert2";
import { throwError } from "rxjs";
import { UpdateUserList } from "./user.state";

export class CreateNotification {
  static readonly type = "[Basic Notification] Create";

  constructor(
    public levelValue: string,
    public resolutionRequired: boolean,
    public description: string,
    public entityId: string,
    public categoryValue: string
  ) {}
}

export class FetchNotification {
  static readonly type = "[Basic Notification] Fetch Single";

  constructor(public notificationId: string) {}
}

export class FetchNotifications {
  static readonly type = "[Basic Notification] Fetch";

  constructor(public userId: string) {}
}

export class ResolveNotification {
  static type = "[Basic Notification]  Resolve";

  constructor(public notification: BasicNotification, public userId: string) {}
}

export class FetchResolved {
  static type = "[Basic Notification] CheckResolved";

  constructor(public notificationId: string) {}
}

export class DeleteNotification {
  static readonly type = "[Basic Notification] Delete";

  constructor(public notificationId: string) {}
}

export class FetchSubscribed {
  static readonly type = "[Basic Notification] CheckSubscribed";

  constructor(public userId: string, public category: string) {}
}

export class FetchSubscribedCategories {
  static readonly type = "[Basic Notification] GetSubscribedCategories";

  constructor(public userId: string) {}
}

export class FetchSubscribedEntities {
  static readonly type = "[Basic Notification] GetSubscribedEntities";

  constructor(public userId: string, public category: string) {}
}

export class ReadNotification {
  static readonly type = "[Basic Notification] ReadNotification";

  constructor(public notification: BasicNotification, public userId: string) {}
}

export class FetchRead {
  static readonly type = "[Basic Notification] CheckRead";

  constructor(public userId: string, public notificationId: string) {}
}

export class SubscribeCategory {
  static readonly type = "[Basic Notification] SubscribeCategory";

  constructor(public userId: string, public categories: string[]) {}
}

export class SubscribeEntity {
  static readonly type = "[Basic Notification] SubscribeEntity";

  constructor(public userId: string, public category: string, public entityIds: string[]) {}
}

export class UnsubscribeCategory {
  static readonly type = "[Basic Notification] UnsubscribeCategory";

  constructor(public userId: string, public categories: string[]) {}
}

export class UnsubscribeEntity {
  static readonly type = "[Basic Notification] UnsubscribeEntity";

  constructor(public entitySubscriptionIds: string[]) {}
}

export interface BasicNotificationStateModel {
  data: BasicNotification[];
  subscribedCategories: string[];
  read: _.Dictionary<boolean>; // A dictionary of which notifications have been read or not. Key is notification ID
  resolved: _.Dictionary<boolean>; // Same as the read dict, but for resolved instead. This isn't meant to really be used
  // and exists primarily to make some of the actions in this file unit testable.
}

@Injectable()
@State<BasicNotificationStateModel>({
  name: "basicNotifications",
  defaults: {
    data: [],
    subscribedCategories: [],
    read: {},
    resolved: {},
  },
})
export class BasicNotificationState {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvService,
    private readonly appInsights: ApplicationInsightsService
  ) {}

  @Selector()
  static lookupById(state: BasicNotificationStateModel) {
    return _.indexBy(state.data, (it) => it.id);
  }

  // Calls the Notification Create() API func
  @Action(CreateNotification)
  createNotification(ctx: StateContext<BasicNotificationStateModel>, action: CreateNotification) {
    // Pass in "action" as the whole parameter here becuase it holds all the params from the CreateNotification class above,
    // and those params are required by the Create() API func
    return this.http.post<BasicNotification>(`${this.envService.apiUri}/api/v1/notifications/create`, action).pipe(
      tap((result) => {
        ctx.setState(patch({ data: insertItem<BasicNotification>(Object.assign(new BasicNotification(), result)) }));
      })
    );
  }

  // Calls the Notification Get(Id) API func
  @Action(FetchNotification)
  fetchNotification(ctx: StateContext<BasicNotificationStateModel>, action: FetchNotification) {
    return this.http
      .get<BasicNotification>(`${this.envService.apiUri}/api/v1/notifications/${action.notificationId}`)
      .pipe(
        tap((notification) =>
          ctx.setState(
            patch({ data: insertItem<BasicNotification>(Object.assign(new BasicNotification(), notification)) })
          )
        )
      );
  }

  // Calls the Notification Get() per user API func
  @Action(FetchNotifications)
  fetchNotifications(ctx: StateContext<BasicNotificationStateModel>, action: FetchNotifications) {
    return this.http
      .get<BasicNotificationHolder[]>(`${this.envService.apiUri}/api/v1/notifications/user/${action.userId}`)
      .pipe(
        tap((notificationHolders) => {
          ctx.setState(
            patch({
              // Map the notificationHolder data (the struct we got from the API call) into the data
              data: notificationHolders
                .map((it) => Object.assign(new BasicNotification(), it.notification))

                // Sort the notifications according to the default order: Most seger at the top, then most recent
                // Largely taken from https://stackoverflow.com/questions/6913512/how-to-sort-an-array-of-objects-by-multiple-fields
                // As suggested by Taylor, may be nice to redo this in the future using underscore.js' library & sortBy()
                // The .sort function can be passed another function (see below). This lambda function evaluates a number to judge whether to sort.
                // The logic below sorts on level, unless the level of the two notifications is equal. Then, it sorts on dateCreated.
                // We subtract instead of use boolean operators because we are evaluating a number--specifically, whether a number is positive, negative, or 0.
                .sort(
                  (a, b) =>
                    this.getLevelNumeric(b.level) - this.getLevelNumeric(a.level) ||
                    // "new Date()"" here because of a javascript quirk. Even though dateCreated is clearly of type Date, when evaluated in the browser it was seeing it as a string.
                    // So, we create new Date()s just to evaulate them here. Likely a mapping issue to look into at a later 'date' ;)
                    new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
                ),
            })
          );
          notificationHolders.forEach(function (holder) {
            // Get the original read & resolved/archived state, and deep copy it into the new state. Allows modification and prevents clobbering the old state
            const ogState = ctx.getState();
            var state = { read: {}, resolved: {} } as BasicNotificationStateModel;
            Object.keys(ogState.read).forEach(function (key) {
              state.read[key] = ogState.read[key];
            });
            Object.keys(ogState.resolved).forEach(function (key) {
              state.resolved[key] = ogState.resolved[key];
            });
            state.read[holder.notification.id] = holder.read;
            state.resolved[holder.notification.id] = null != holder.notification.dateResolved; // If it's been archived, mark it as such in the dictionary
            ctx.setState(patch(state));
          });
        })
      );
  }

  // Calls the Notification ResolveNotification() API func
  @Action(ResolveNotification)
  resolveNotification(ctx: StateContext<BasicNotificationStateModel>, action: ResolveNotification) {
    // Last param is null here because the API func doesn't access anything in the body
    return this.http
      .put<BasicNotification>(
        `${this.envService.apiUri}/api/v1/notifications/${action.notification.id}/resolve/${action.userId}`,
        null
      )
      .pipe(
        tap((result) => {
          // Update the notification itself
          const newNotification = Object.assign(new BasicNotification(), {
            ...action.notification,
            ...{
              resolvedBy: action.userId,
              dateResolved: Date.UTC, // The current UTC time
            },
          });
          ctx.setState(
            patch({ data: updateItem<BasicNotification>(({ id }) => id === action.notification.id, newNotification) })
          );

          // Update the dictionary of resolved notifications
          const ogState = ctx.getState();
          var state = { resolved: {} } as BasicNotificationStateModel;
          Object.keys(ogState.resolved || {}).forEach(function (key) {
            state.resolved[key] = ogState.resolved[key];
          });
          state.resolved[action.notification.id] = true;
          ctx.setState(patch(state));
        })
      );
  }

  // Calls the Notification DeleteNotification API func
  @Action(DeleteNotification)
  deleteNotification(ctx: StateContext<BasicNotificationStateModel>, action: DeleteNotification) {
    return this.http.delete(`${this.envService.apiUri}/api/v1/notifications/${action.notificationId}`).pipe(
      tap(() => {
        ctx.setState(patch({ data: removeItem<BasicNotification>(({ id }) => id === action.notificationId) }));
      })
    );
  }

  // Calls the Notification ReadNotification() API func
  @Action(ReadNotification)
  readNotification(ctx: StateContext<BasicNotificationStateModel>, action: ReadNotification) {
    // Last param is null here because the API func doesn't access anything in the body
    return this.http
      .put<BasicNotification>(
        `${this.envService.apiUri}/api/v1/notifications/${action.notification.id}/read/${action.userId}`,
        null
      )
      .pipe(
        tap((result) => {
          // Get the original read state, and deep copy it into the new state. Allows modification and prevents clobbering the old state
          const ogState = ctx.getState();
          var state = { read: {} } as BasicNotificationStateModel;
          Object.keys(ogState.read).forEach(function (key) {
            state.read[key] = ogState.read[key];
          });
          state.read[action.notification.id] = true;
          ctx.setState(patch(state));
        })
      );
  }

  // Calls the Notification CheckRead() API func
  @Action(FetchRead)
  fetchRead(ctx: StateContext<BasicNotificationStateModel>, action: FetchRead) {
    // Last param is null here because the API func doesn't access anything in the body
    return this.http
      .get<boolean>(
        `${this.envService.apiUri}/api/v1/notifications/user/${action.userId}/read/${action.notificationId}`,
        null
      )
      .pipe(
        tap((result) => {
          // Get the original read state, and deep copy it into the new state. Allows modification and prevents clobbering the old state
          const ogState = ctx.getState();
          var state = { read: {} } as BasicNotificationStateModel;
          Object.keys(ogState.read).forEach(function (key) {
            state.read[key] = ogState.read[key];
          });
          state.read[action.notificationId] = (result as HttpResponse<boolean>).body;
          ctx.setState(patch(state));
        })
      );
  }

  // Calls the Notification CheckResolved() API func
  // This is a weird one. We don't track "resolved" anywhere, but we track resolved date and resolvedBy. This just returns a boolean,
  // So we can't update either of those state values with the result of this query. Thus, I've added the resolved dictionary
  // to the state so that it can be updated for the sake of the unit tests. However, there isn't any need to access that data
  // outside of the unit tests, because you can just check the resolvedDate or resolvedBy.
  @Action(FetchResolved)
  fetchResolved(ctx: StateContext<BasicNotificationStateModel>, action: FetchResolved) {
    // Last param is null here because the API func doesn't access anything in the body
    return this.http
      .get<boolean>(`${this.envService.apiUri}/api/v1/notifications/${action.notificationId}/resolved`, null)
      .pipe(
        tap((result) => {
          // Get the original read state, and deep copy it into the new state. Allows modification and prevents clobbering the old state
          const ogState = ctx.getState();
          var state = { resolved: {} } as BasicNotificationStateModel;
          Object.keys(ogState.resolved).forEach(function (key) {
            state.resolved[key] = ogState.resolved[key];
          });
          state.resolved[action.notificationId] = (result as HttpResponse<boolean>).body;
          ctx.setState(patch(state));
        })
      );
  }

  // TODO
  // Fetch whether the user is subscribed toa  specific category. Unclear if we would ever use this vs FetchSubscribedCategories
  @Action(FetchSubscribed)
  fetchSubscribed(ctx: StateContext<BasicNotificationStateModel>, action: FetchSubscribed) {}

  // Fetch subscriptions for a particular user
  @Action(FetchSubscribedCategories)
  fetchSubscribedCategories(ctx: StateContext<BasicNotificationStateModel>, action: FetchSubscribedCategories) {
    return this.http
      .get<string[]>(`${this.envService.apiUri}/api/v1/notifications/user/${action.userId}/categories`)
      .pipe(
        tap((result) => {
          ctx.setState(patch({ subscribedCategories: result }));
        })
      );
  }

  // TODO
  @Action(FetchSubscribedEntities)
  fetchSubscribedEntities(ctx: StateContext<BasicNotificationStateModel>, action: FetchSubscribedEntities) {}

  // Subscribe a user to a given notification category
  @Action(SubscribeCategory)
  subscribeCategory(ctx: StateContext<BasicNotificationStateModel>, action: SubscribeCategory) {
    return this.http
      .put(`${this.envService.apiUri}/api/v1/notifications/subscribe/${action.userId}`, action.categories)
      .pipe(
        tap(() => {
          const ogState = ctx.getState();
          var state = { subscribedCategories: [] } as BasicNotificationStateModel;
          Object.keys(ogState.subscribedCategories).forEach(function (key) {
            state.subscribedCategories[key] = ogState.subscribedCategories[key];
          });

          // Go through each category and subscribe to it.
          // If "None" is present at any point, clear the categories, add "none", and stop adding any further ones.
          action.categories.forEach(function (category) {
            if ("None" === category) {
              // Empty the array before adding "None" after the if statement. Then exit
              state.subscribedCategories = [];
              state.subscribedCategories.push(category);
              ctx.setState(patch(state));
              return;
            } else {
              // Remove the "None" category if we're adding a real category
              state.subscribedCategories = state.subscribedCategories.filter((cat) => cat != "None");
              state.subscribedCategories.push(category);
            }
          });
          ctx.setState(patch(state));
        })
      );
  }

  // TODO
  @Action(SubscribeEntity)
  subscribeEntity(ctx: StateContext<BasicNotificationStateModel>, action: SubscribeEntity) {}

  // Unsubscribe a user from a given notification category
  @Action(UnsubscribeCategory)
  unsubscribeCategory(ctx: StateContext<BasicNotificationStateModel>, action: UnsubscribeCategory) {
    // Last param is null here because the API func doesn't access anything in the body
    return this.http
      .put(`${this.envService.apiUri}/api/v1/notifications/unsubscribe/${action.userId}`, action.categories)
      .pipe(
        tap(() => {
          const ogState = ctx.getState();
          var state = { subscribedCategories: [] } as BasicNotificationStateModel;
          Object.keys(ogState.subscribedCategories).forEach(function (key) {
            state.subscribedCategories[key] = ogState.subscribedCategories[key];
          });
          action.categories.forEach(function (category) {
            // Remove the given category from the array
            state.subscribedCategories = state.subscribedCategories.filter((cat) => cat != category);
          });
          ctx.setState(patch(state));
        })
      );
  }

  // TODO
  @Action(UnsubscribeEntity)
  unsubscribeEntity(ctx: StateContext<BasicNotificationStateModel>, action: UnsubscribeEntity) {}

  // Convert string value of severity to an integer so that we can sort by that.
  // Otherwise we will just sort alphabetically which is not helpful
  private getLevelNumeric(level: string): number {
    switch (level) {
      case "Error": {
        return 3;
        break;
      }
      case "Warning": {
        return 2;
        break;
      }
      case "Info": {
        return 1;
        break;
      }
      default: {
        return 0;
        break;
      }
    }
  }
}
