// A guard to check against various roles. To use properly,
// the "claims" data will need to be added to the routing page
// Example:
//
//path: "dashboard",
//        loadChildren: () => import("./areas/dashboard/dashboard.module").then(m => m.DashboardModule),
//        canActivate: [AuthGuard, RolesGuard],
//        data: {
//          claims: [
//            UserClaims[UserClaims.ViewDashboard],
//            UserClaims[UserClaims.EditDashboard]
//          ]
//        }

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { UserService } from "../user.service";

@Injectable({
  providedIn: "root",
})
export class RolesGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private readonly ngRouter: Router,
    private readonly userService: UserService,
    private readonly authService: AuthService
  ) {}

  // Async simply to allow it to be properly unit testable. In the real world, it works find with or without async
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // If no Userclaim was specified
    if (!route.data || !route.data.claims || !route.data.claims.length) {
      return true;
    }

    // Evaluate the claims of the user--do they have permissions to access this route?
    var claimPromise = this.checkClaims(route);
    return claimPromise.then((result) => {
      if (result) {
        return true;
      }
      return this.ngRouter.parseUrl("/not-authorized");
    });
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // If no Userclaim was specified
    if (!childRoute.data.claims || !childRoute.data.claims.length) {
      return true;
    }

    // Evaluate the claims of the user--do they have permissions to access this route?
    var claimPromise = this.checkClaims(childRoute);
    return claimPromise.then((result) => {
      if (result) {
        return true;
      }
      return this.ngRouter.parseUrl("/not-authorized");
    });
  }

  canLoad(route: Route): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // If no Userclaim was specified
    if (!route.data.claims || !route.data.claims.length) {
      return true;
    }

    if (!this.authService.isLoggedIn()) {
      return this.ngRouter.parseUrl("/auth/login");
    }

    // Evaluate the claims of the user--do they have permissions to access this route?
    var claimPromise = this.checkClaims(route);
    return claimPromise.then((result) => {
      if (result) {
        return true;
      }
      return this.ngRouter.parseUrl("/not-authorized");
    });
  }

  // Iterate through the list of route claims. If the user matches any one of them, return true.
  // Otherwise, direct to not authorized page.
  public async checkClaims(route: ActivatedRouteSnapshot | Route): Promise<boolean> {
    for (let claim of route.data.claims) {
      var hasClaim$ = await this.userService.user$
        .pipe(
          map((user) => user != null && user.userClaims.includes(claim)),
          catchError(() => of(false))
        )
        .toPromise();
      if (hasClaim$) {
        // If they have the claim we're checking, grant access
        return of(true).toPromise();
      }
    }
    // If they had none of the claims we checked, deny access
    return of(false).toPromise();
  }
}
