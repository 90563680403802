import { Component, OnInit } from "@angular/core";

@Component({
  selector: "authorize",
  templateUrl: "./authorize.component.html",
  styleUrls: ["./authorize.component.scss"],
})
export class AuthorizeComponent {
  constructor() {}
}
