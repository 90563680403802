import { Directive, ElementRef, Input, OnChanges, OnInit } from "@angular/core";

@Directive({
  selector: "[nullFill]",
})
export class NullFillDirective implements OnInit, OnChanges {
  @Input("nullFill") data: any;

  constructor(private readonly el: ElementRef) {}

  ngOnInit() {
    if (this.data == null) {
      this.el.nativeElement.style.backgroundColor = "lightgrey";

      this.el.nativeElement.style.color = "transparent";
    }
  }

  ngOnChanges() {
    if (this.data == null) {
      this.el.nativeElement.style.backgroundColor = "lightgrey";

      this.el.nativeElement.style.color = "transparent";
    } else {
      this.el.nativeElement.style.backgroundColor = "inherit";
      this.el.nativeElement.style.color = "inherit";
    }
  }
}
