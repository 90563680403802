export class BasicNotification {
  id: string;
  companyId: string;
  level: string; //enum
  resolvedBy: string;
  dateResolved: Date;
  resolutionRequired: boolean;
  dateCreated: Date;
  description: string;
  category: string; //enum
  entityID: string;
}
