export enum UserClaims {
  // Basic login
  Login,

  //Dashboard
  ViewDashboard,
  EditDashboard,

  // Device Management (menu)
  DeviceMgmtMenu,

  // Device Management - Fleet
  ViewFleet,
  EditFleet,

  // Device Management - Device
  ViewDevice,
  EditDevice,

  // Device Management - Device Detail
  ViewDeviceDetail,
  EditDeviceDetail,

  // Device Management - Device Detail, actions, console
  ViewActionsConsole,
  EditActionsConsole,

  // Device Management - Device Detail, logs
  Logs,

  // Device Management - Device Detail, base console
  BaseConsole,

  // Data Management (menu)
  DataMgmtMenu,

  // Data Management - Summary
  DataMgmtSummary,

  // Data Management - Summary, detail
  ViewDataMgmtDetail,
  EditDataMgmtDetail,

  // Data Management - Data Routes
  ViewDataRoutes,
  EditDataRoutes,

  // App/Setting Management (menu)
  AppMgmtMenu,

  // App/Setting Management - Deployments
  ViewDeployments,
  EditDeployments,

  // App/Setting Management - Catalogs
  ViewCatalogs,
  EditCatalogs,

  // App/Setting Management - Packages
  ViewPackages,
  EditPackages,

  // Admin - Users
  AdminUsers,

  // Admin - Roles
  AdminRoles,

  // Admin - Site Settings
  AdminSettings,

  // Admin - Device Assignment
  AdminDeviceAssignment,

  // Can see/assign ballard devs--TBD
  AdminBallardDevAssignment,
}
