export class LogSchedule {
  runScheduled: boolean;
  scheduledTime: string;
  runWeekly: boolean;
  dayToRun: number;
  webhookEnabled: boolean;
  webhookUrl: string;
  webhookSourceName: string;
  authenticateRequest: boolean;
  username: string;
  password: string;
}
