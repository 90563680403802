import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Device } from "src/app/shared/models/device.model";
import { Store } from "@ngxs/store";
import { EnvService } from "src/app/core/env.service";
import { UpdateDeviceAdmin } from "src/app/core/state-management/device.state";
import Swal from "sweetalert2";
import { HttpClient } from "@angular/common/http";
import { Company } from "src/app/shared/models/company.model";
import * as _ from "lodash";
import { Observable } from "rxjs";

@Component({
  selector: "device-assign-modal",
  templateUrl: "./device-assign-modal.component.html",
  styleUrls: ["./device-assign-modal.component.scss"],
})
export class DeviceAssignModalComponent implements OnInit {
  @Input() public device: Device;
  @Input() public assignedCompany: string;
  assignmentForm: FormGroup<{ selectedCompany: FormControl<string> }>;

  // List of all unique companies
  companies: Company[] = [];
  allCompanyList$: Observable<Company[]>;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly http: HttpClient,
    private readonly envService: EnvService,
    private readonly store: Store,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.assignmentForm = this.fb.group({
      selectedCompany: new FormControl<string>("", [companyChangedValidator(this.assignedCompany)]),
    });

    this.allCompanyList$ = this.fetchCompanies();
  }

  reassign() {
    var e = document.getElementById("selectedCompany") as HTMLSelectElement;
    var sel = e.selectedIndex;
    var opt = e.options[sel];
    var newCompanyId = opt.value;
    var newCompanyName = opt.text;

    Swal.fire({
      title: "Are you sure?",
      text: `This will assign ${this.device.deviceType}-${this.device.serialNumber} to ${newCompanyName} and clean the device of identifying data.`,
      icon: "warning",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        // Creating new device because the component's device is readonly
        var newDevice = new Device();
        Object.assign(newDevice, this.device);
        newDevice.companyId = newCompanyId;
        this.store.dispatch(new UpdateDeviceAdmin(newDevice));

        this.activeModal.close("submitted");
        return;
      },
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  fetchCompanies() {
    return this.http.get<Company[]>(`${this.envService.apiUri}/api/v1/company`);
  }
}

function companyChangedValidator(company: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == "" || control.value === company) {
      return { SelectionInvalid: true };
    }
    return null;
  };
}
