import * as moment from "moment";

/* Format the time and/or date into a
valid ISO 8601-compliant string.*/
export function toUTCString(date: Date | string | moment.Moment, dateOnly: Boolean = false, timeOnly: Boolean = false) {
  // Convert string/Date to UTC Moment
  date = moment(date).utc();
  if (date.isValid()) {
    if (!dateOnly && !timeOnly) {
      return date.format("YYYY-MM-DDTHH:mm:ss") + "Z";
    } else if (dateOnly) {
      return date.format("YYYY-MM-DD");
    } else if (timeOnly) {
      return date.format("HH:mm:ss") + "Z";
    } else {
      return "";
    }
  }

  // Return "N/A" instead of "Invalid date"
  return "N/A";
}
