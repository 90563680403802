import { Catalog } from "./catalog.model";
import { Collection } from "./collection.model";
import { DeploymentStatus } from "./deployment-status.model";

export class Deployment {
  constructor(obj?: Deployment) {
    if (obj != null) {
      Object.assign(this, obj);

      this.dateEffective = new Date(obj.dateEffective);
      this.dateCreated = new Date(obj.dateCreated);

      this.status = obj.status.map((it2) => {
        it2.statusDate = new Date(it2.statusDate);

        const status = Object.assign(new DeploymentStatus(), it2);

        return status;
      });
    }
  }

  dateCreated: Date;
  dateEffective: Date;
  dateArchived: Date;
  name: string;
  id: string;
  catalog: Catalog;
  status: DeploymentStatus[];
  collection: Collection;
}
