import { Component, OnInit } from "@angular/core";
import { combineLatest } from "rxjs";
import { take } from "rxjs/operators";
import { DevicePageService } from "src/app/areas/devices/services/device-page.service";
import { ApplicationInsightsService } from "src/app/core/application-insights.service";
import { UserService } from "src/app/core/user.service";
import { UserClaims } from "src/app/shared/user-claims.enum";

@Component({
  selector: "app-content-layout",
  templateUrl: "./content-layout.component.html",
  styleUrls: ["./content-layout.component.scss"],
})
export class ContentLayoutComponent implements OnInit {
  claims = UserClaims;

  constructor(
    private readonly devicePage: DevicePageService,
    public readonly userService: UserService,
    private readonly appInsights: ApplicationInsightsService
  ) {}

  ngOnInit() {
    combineLatest([this.userService.user$, this.userService.company$])
      .pipe(take(1))
      .subscribe(([user, company]) => this.appInsights.setUserContext(user, company));
  }
}
