import { EnvService } from "./env.service";
import { environment } from "src/environments/environment";

export function EnvServiceFactory() {
  // Create env
  const env = new EnvService();

  // Read environment variables from browser window
  const browserWindow = window || {};

  // eslint-disable-next-line @typescript-eslint/dot-notation
  const browserWindowEnv = browserWindow["__env"] || {};

  // Assign environment variables from browser window to env
  // In the current implementation, properties from env.js overwrite defaults from the EnvService.
  // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
  for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      // Set the EnvService variables according to what's in env.js
      // eslint-disable-next-line @typescript-eslint/dot-notation
      env[key] = window["__env"][key];
      console.log("Setting " + key + " environment property to " + window["__env"][key]);
    }
  }

  // Next, overwrite a few of the EnvService variables with custom environment configuration values.
  // For example, if "ng serve -c local" is called, then the environment variables for environment.local.ts will be substituted.
  // If no custom configuration is used (or if dev is used, which has the same values as env.js), we replace nothing.
  if ("dev" != environment.name && "prod" != environment.name && "production" != environment.name) {
    // Only overwrite the apiUri since that's all that has to change.
    // The clientId is the same across all the environments that we use
    env["apiUri"] = environment.apiUri;
  }

  return env;
}
