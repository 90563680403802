import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { actionsExecuting, ActionsExecuting } from "src/app/core/state-management/ngxs/actions-executing";
import { Select, Store } from "@ngxs/store";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import {
  DeploymentState,
  DeploymentStateModel,
  FetchCollectionDeployments,
  FetchDeployments,
} from "src/app/core/state-management/deployment.state";
import { FetchDevices } from "src/app/core/state-management/device.state";
import { UserClaims } from "src/app/shared/user-claims.enum";
import { UserService } from "src/app/core/user.service";
import { DeploymentResult } from "src/app/shared/deployment-result.enum";
import { Deployment } from "src/app/shared/models/deployment.model";
import * as _ from "underscore";
import { toUTCString } from "src/app/shared/utc";

@Component({
  selector: "deployment-list",
  templateUrl: "./deployment-list.component.html",
  styleUrls: ["./deployment-list.component.scss"],
})
export class DeploymentListComponent implements OnInit {
  @Input() collectionId: string;

  //  @Select(actionsExecuting([FetchDeployments, FetchCollectionDeployments])) loading$: Observable<ActionsExecuting>;
  loading$: Observable<ActionsExecuting>;
  //  @Select(DeploymentState) deployments$: Observable<DeploymentStateModel>;
  deployments$: Observable<DeploymentStateModel>;
  //  @Select(DeploymentState.lookupById) deploymentLookup$: Observable<_.Dictionary<Deployment>>;
  deploymentLookup$: Observable<_.Dictionary<Deployment>>;
  toUTCString = toUTCString;

  showArchived$ = new BehaviorSubject<boolean>(false);
  successfulDeployments$: Observable<_.Dictionary<number>>;
  filteredDeployments$: Observable<Deployment[]>;

  claims = UserClaims;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    public readonly userService: UserService
  ) {}

  ngOnInit() {
    this.loading$ = this.store.select(actionsExecuting([FetchDeployments, FetchCollectionDeployments]));
    this.deployments$ = this.store.select(DeploymentState);
    this.deploymentLookup$ = this.store.select(DeploymentState.lookupById);

    this.successfulDeployments$ = this.store.select(DeploymentState.lookupByTypeAndId).pipe(
      map(
        (filterFn) =>
          filterFn(this.collectionId != null ? this.collectionId : "all") || ({} as _.Dictionary<Deployment>)
      ),
      map((lookup) =>
        _.mapObject(
          lookup,
          (deployment) =>
            deployment.status.filter((it) => it.deploymentResult === DeploymentResult.CompletedWithSuccess).length
        )
      )
    );

    this.filteredDeployments$ = combineLatest([this.deployments$, this.showArchived$]).pipe(
      map(([deployments = { data: {} } as DeploymentStateModel, showArchived]) =>
        (deployments.data[this.collectionId != null ? this.collectionId : "all"] || []).filter(
          (deployment) => showArchived || deployment.dateArchived == null
        )
      )
    );

    this.store.dispatch([
      new FetchDevices(false),
      this.collectionId != null ? new FetchCollectionDeployments(this.collectionId) : new FetchDeployments(),
    ]);
  }

  viewDeployment(deployment: Deployment) {
    this.router.navigate(["deployments", `${deployment.id}`]);
  }

  toggleHidden() {
    this.showArchived$
      .pipe(
        take(1),
        tap((showArchived) => this.showArchived$.next(!showArchived))
      )
      .subscribe();
  }
}
