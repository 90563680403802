import { DeploymentResult } from "../deployment-result.enum";
import { Device } from "./device.model";

export class DeploymentStatus {
  id: string;
  resultMessage: string;
  deploymentResult: DeploymentResult;
  statusDate: Date;
  device: Device;

  get statusDisplay() {
    switch (this.deploymentResult) {
      case "CompletedWithSuccess":
        return "Installed";
      case "CompletedWithError":
        return "Failed";
    }

    return this.deploymentResult;
  }
}
