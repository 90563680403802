import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DeviceAssignmentComponent } from "./areas/admin/components/device-assignment/device-assignment.component";
import { LogScheduleComponent } from "./areas/admin/components/log-schedule/log-schedule.component";
import { RoleListComponent } from "./areas/admin/components/role-list/role-list.component";
import { UsersComponent } from "./areas/admin/components/users/users.component";
import { AuthorizeGuard } from "./areas/auth/authorize.guard";
import { AuthorizeComponent } from "./areas/auth/authorize/authorize.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { RolesGuard } from "./core/guards/roles.guard";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { ContentLayoutComponent } from "./layouts/content-layout/content-layout.component";
// eslint-disable-next-line max-len
import { NotAuthorizedComponent } from "./shared/components/not-authorized/not-authorized.component";
import { UserClaims } from "./shared/user-claims.enum";

// Declare all the route objects that use the RolesGuard. This allows us to use them in unit tests 1:1
export const dashboardRoute = {
  path: "dashboard",
  loadChildren: () => import("./areas/dashboard/dashboard.module").then((m) => m.DashboardModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.ViewDashboard], UserClaims[UserClaims.EditDashboard]],
  },
};

export const fleetRoute = {
  path: "fleets",
  loadChildren: () => import("./areas/fleet/fleet.module").then((m) => m.FleetModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.ViewFleet], UserClaims[UserClaims.EditFleet]],
  },
};

export const aircraftRoute = {
  path: "aircraft",
  loadChildren: () => import("./areas/aircraft/aircraft.module").then((m) => m.AircraftModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.ViewFleet], UserClaims[UserClaims.EditFleet]],
  },
};

export const deviceRoute = {
  path: "devices",
  loadChildren: () => import("./areas/devices/device.module").then((m) => m.DeviceModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.ViewDevice], UserClaims[UserClaims.EditDevice]],
  },
};

export const packagesRoute = {
  path: "packages",
  loadChildren: () => import("./areas/packages-area/package.module").then((m) => m.PackageModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.ViewPackages], UserClaims[UserClaims.EditPackages]],
  },
};

export const datamgmtsummaryRoute = {
  path: "data-mgmt-summary",
  loadChildren: () => import("./areas/data-mgmt-summary/data-mgmt-summary.module").then((m) => m.DataMgmtSummaryModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.DataMgmtSummary]],
  },
};

export const datamgmtroutingRoute = {
  path: "data-mgmt-routing",
  loadChildren: () => import("./areas/data-mgmt-routing/data-mgmt-routing.module").then((m) => m.DataMgmtRoutingModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.ViewDataRoutes], UserClaims[UserClaims.EditDataRoutes]],
  },
};

export const catalogsRoute = {
  path: "catalogs",
  loadChildren: () => import("./areas/catalogs/catalog.module").then((m) => m.CatalogModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.ViewCatalogs], UserClaims[UserClaims.EditCatalogs]],
  },
};

export const deploymentsRoute = {
  path: "deployments",
  loadChildren: () => import("./areas/deployments/deployment.module").then((m) => m.DeploymentModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.ViewDeployments], UserClaims[UserClaims.EditDeployments]],
  },
};

export const adminusersRoute = {
  path: "admin/users",
  component: UsersComponent,
  loadChildren: () => import("./areas/admin/admin.module").then((m) => m.AdminModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.AdminUsers]],
  },
};

export const adminrolesRoute = {
  path: "admin/roles",
  component: RoleListComponent,
  loadChildren: () => import("./areas/admin/admin.module").then((m) => m.AdminModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.AdminRoles]],
  },
};

export const admindevicelogsRoute = {
  path: "admin/device-logs",
  component: LogScheduleComponent,
  loadChildren: () => import("./areas/admin/admin.module").then((m) => m.AdminModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.AdminSettings]],
  },
};

export const admindeviceassignmentRoute = {
  path: "admin/device-assignment",
  component: DeviceAssignmentComponent,
  loadChildren: () => import("./areas/admin/admin.module").then((m) => m.AdminModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [UserClaims[UserClaims.AdminDeviceAssignment]],
  },
};

export const helpRoute = {
  path: "help",
  loadChildren: () => import("./areas/help/help.module").then((m) => m.HelpModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [
      // We don't have a claim for help, but No Access role shouldn;t be able to see it.
      // So, validating via the most basic access--dashbard viewing
      UserClaims[UserClaims.ViewDashboard],
      UserClaims[UserClaims.EditDashboard],
    ],
  },
};

export const settingsRoute = {
  path: "settings",
  loadChildren: () => import("./areas/settings/settings.module").then((m) => m.SettingsModule),
  canActivate: [AuthGuard, RolesGuard],
  canLoad: [RolesGuard],
  canActivateChild: [AuthGuard, RolesGuard],
  data: {
    claims: [
      // Validating via the most basic access--dashbard viewing
      UserClaims[UserClaims.ViewDashboard],
      UserClaims[UserClaims.EditDashboard],
    ],
  },
};

export const routes: Routes = [
  {
    path: "",
    component: ContentLayoutComponent,
    children: [
      dashboardRoute,
      fleetRoute,
      aircraftRoute,
      deviceRoute,
      packagesRoute,
      datamgmtsummaryRoute,
      datamgmtroutingRoute,
      catalogsRoute,
      deploymentsRoute,
      adminusersRoute,
      adminrolesRoute,
      admindevicelogsRoute,
      admindeviceassignmentRoute,
      helpRoute,
      settingsRoute,
      {
        path: "not-authorized",
        component: NotAuthorizedComponent,
      },
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
    ],
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: () => import("./areas/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "authorize",
    component: AuthorizeComponent,
    canActivate: [AuthorizeGuard],
  },
  {
    path: "logout",
    redirectTo: "/auth/logout",
    pathMatch: "full",
  },
  {
    path: "error",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
