import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";

@Injectable()
export class AuthService {
  constructor(private readonly msalService: MsalService) {}

  isLoggedIn() {
    return this.msalService.instance.getAllAccounts().length > 0;
  }
}
