import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { tap } from "rxjs/operators";
import { LogSchedule } from "src/app/shared/models/log-schedule.model";
import Swal from "sweetalert2";
import { ApplicationInsightsService } from "../application-insights.service";
import { EnvService } from "../env.service";

export class FetchLogSchedule {
  static readonly type = "[LogScheduleItem] Fetch";

  constructor() {}
}

export class SaveLogSchedule {
  static readonly type = "[LogScheduleItem] Save";

  constructor() {}
}

export interface LogScheduleStateModel {
  logScheduleForm: {
    model: LogSchedule;
    dirty: boolean;
    status: string;
    errors: any;
  };
}

@Injectable()
@State<LogScheduleStateModel>({
  name: "logScheduleFormState",
  defaults: {
    logScheduleForm: {
      model: undefined,
      dirty: false,
      status: "",
      errors: {},
    },
  },
})
export class LogScheduleState {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvService,
    private readonly appInsights: ApplicationInsightsService
  ) {}

  @Action(FetchLogSchedule)
  fetchLogSchedule(ctx: StateContext<LogScheduleStateModel>) {
    return this.http
      .get<LogSchedule>(`${this.envService.apiUri}/api/v1/logSchedule`)
      .pipe(tap((logScheduleItem) => ctx.setState(patch({ logScheduleForm: patch({ model: logScheduleItem }) }))));
  }

  @Action(SaveLogSchedule)
  saveLogSchedule(ctx: StateContext<LogScheduleStateModel>) {
    const state = ctx.getState();

    this.appInsights.trackEvent({
      name: "Settings logs save",
    });

    return this.http.put(`${this.envService.apiUri}/api/v1/logSchedule`, state.logScheduleForm.model).pipe(
      tap(() => {
        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          title: `Log Schedule Updated`,
        });
      })
    );
  }
}
