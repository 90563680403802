import { Component, OnInit } from "@angular/core";

@Component({
  selector: "role-list",
  templateUrl: "./role-list.component.html",
  styleUrls: ["./role-list.component.scss"],
})
export class RoleListComponent {
  constructor() {}
}
