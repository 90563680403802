import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import {
  BasicNotificationStateModel,
  FetchNotifications,
  ReadNotification,
  ResolveNotification,
} from "src/app/core/state-management/basic-notification.state";
import { BasicNotification } from "src/app/shared/models/basic-notification.model";
import Swal from "sweetalert2";
import { actionsExecuting, ActionsExecuting } from "src/app/core/state-management/ngxs/actions-executing";
import { Observable, Subject } from "rxjs";
import { toUTCString } from "src/app/shared/utc";
import { map, tap, take } from "rxjs/operators";
import { UserService } from "src/app/core/user.service";
import { UserClaims } from "src/app/shared/user-claims.enum";

@Component({
  selector: "notifications-modal",
  templateUrl: "./notifications-modal.component.html",
  styleUrls: ["./notifications-modal.component.scss"],
})
export class NotificationsModalComponent implements OnInit, OnDestroy {
  @Input() public userId: string;
  @Input() notifState$: Observable<BasicNotificationStateModel>;
  //  @Select(actionsExecuting([FetchNotifications])) fetching$: Observable<ActionsExecuting>;
  fetching$: Observable<ActionsExecuting>;
  unreadNotifs$: Observable<BasicNotification[]>; // Track unread count
  archivedNotifs$: Observable<BasicNotification[]>; // Track archived count
  toUTCString = toUTCString;
  claims = UserClaims;

  // Whether the user can archive notifications
  canArchive = false;

  // # of unread notifications
  unreadNotificationCt: number;

  // # of archived notifications
  archivedNotificationCt: number;

  private onDestroy$ = new Subject();

  showingArchived = false; // Whether to show archive notifications

  levelList = {
    Info: {
      color: "#5383AD",
      icon: "fa-info-circle",
    },
    Warning: {
      color: "orange",
      icon: "fa-exclamation-triangle",
    },
    Error: {
      color: "#952337",
      icon: "fa-times-circle",
    },
  };

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store,
    private readonly router: Router,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.fetching$ = this.store.select(actionsExecuting([FetchNotifications]));

    this.store.dispatch(new FetchNotifications(this.userId));

    // Filter down to unread and unarchived
    this.unreadNotifs$ = this.notifState$.pipe(
      map((it) => {
        const readMap = it.read;
        return it.data.filter((notif) => !readMap[notif.id] && notif.dateResolved === null);
      })
    );

    // Filter down to archived
    this.archivedNotifs$ = this.notifState$.pipe(
      map((it) => {
        const archivedMap = it.resolved;

        return it.data.filter((notif) => archivedMap[notif.id]);
      })
    );

    // Establish whether user is an admin
    this.userService.user$
      .pipe(
        tap((user) => {
          this.canArchive = user.isAdmin();
        }),
        take(1)
      )
      .subscribe();

    // Get count of results
    this.unreadNotifs$.subscribe((data) => (this.unreadNotificationCt = data.length));
    this.archivedNotifs$.subscribe((archived) => (this.archivedNotificationCt = archived.length));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // Hide or show archived notifications based on what the user selects
  // hide: boolean determining whether to hide or show. true = hide, false = show
  hideArchived(hide: boolean) {
    this.showingArchived = !hide;
  }

  viewNotifSettings() {
    this.router.navigate(["settings"]);
    this.closeModal();
  }

  closeModal() {
    this.activeModal.close();
  }

  // Mark a notification as read
  // notif: notification to mark
  // event: the click event (if applicable)
  read(notif: BasicNotification, event: Event) {
    if (null != event) {
      // Stop selecting the button in the row from also triggering the row's (click) event
      event.stopPropagation();
    }
    // Mark notification as read
    this.store.dispatch(new ReadNotification(notif, this.userId));
  }

  // Mark a notification as archived
  // notif: notification to mark
  // event: the click event (if applicable)
  archive(notif: BasicNotification, event: Event) {
    if (null != event) {
      // Stop selecting the button in the row from also triggering the row's (click) event
      event.stopPropagation();
    }
    // Mark notification as resolved
    this.store.dispatch(new ResolveNotification(notif, this.userId));
  }

  openDetail(notif: BasicNotification) {
    this.read(notif, null);

    let htmlBody = `<div class="mt-2" style="overflow:auto">    
    \<table class="table table-striped table-sm" style="text-align:left;">`;
    htmlBody +=
      `<tr><td>Level:  <span style="color: ` +
      this.levelList[notif.level].color +
      `"class="fas ` +
      this.levelList[notif.level].icon +
      `"></span> ` +
      notif.level +
      `</td></tr>`;
    htmlBody += `<tr><td>Category: ` + notif.category + `</td></tr>`;
    htmlBody += `<tr><td>Created at: ` + toUTCString(notif.dateCreated) + `</td></tr>`;
    htmlBody += `<tr><td>Description: ` + notif.description + `</td></tr>`;
    htmlBody += `</table>`;
    htmlBody += `</div>`;

    Swal.fire({
      html: htmlBody,
      showCloseButton: true,
      focusConfirm: true,
      showCancelButton: true, // Use the cancel button to do nothing
      showDenyButton: this.canArchive,
      denyButtonText: "Archive", // Use the deny button to provide archive functionality
      confirmButtonText: "Read", // Use the confirm button to provide read functionality (i.e. do nothing. See comment below.)
      confirmButtonColor: "#5383ad", // CSS $primary-light var
      denyButtonColor: "#002957", // CSS $primary var
    }).then((result) => {
      if (result.isConfirmed) {
        // Confirming here is aligned with reading the notification
        // So, we do nothing--the notification is read because we opened it.
        // This button just exists so the user thinks there's a way to exit without doing anything
      } else if (result.isDenied) {
        // Denying here is aligned with archiving
        this.archive(notif, null);
      }
    });
  }
}
