import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthorizeGuard } from "../areas/auth/authorize.guard";
import { AuthorizeComponent } from "../areas/auth/authorize/authorize.component";
import { DeploymentListComponent } from "../areas/deployments/components/deployment-list/deployment-list.component";
import { NotAuthorizedComponent } from "./components/not-authorized/not-authorized.component";
import { NullFillDirective } from "./directives/null-fill.directive";
import { RolesGuard } from "../core/guards/roles.guard";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [CommonModule, RouterModule, NgbTooltipModule],
  declarations: [AuthorizeComponent, NotAuthorizedComponent, NullFillDirective, DeploymentListComponent],
  exports: [AuthorizeComponent, NotAuthorizedComponent, NullFillDirective, DeploymentListComponent],
  providers: [AuthorizeGuard, RolesGuard],
})
export class SharedModule {}
