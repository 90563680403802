import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { Observable, of, timer } from "rxjs";
import { flatMap } from "rxjs/operators";

@Injectable()
export class LoadingStrategyService implements PreloadingStrategy {
  constructor(private readonly msalService: MsalService) {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.path.startsWith("auth")) {
      return of(null);
    }

    return timer(2000).pipe(flatMap(() => load()));
  }
}
