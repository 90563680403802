import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { BehaviorSubject, Observable, Subject, timer } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { EnvService } from "src/app/core/env.service";
import { DeviceState, DeviceStateModel, FetchDevicesAssignments } from "src/app/core/state-management/device.state";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/core/user.service";
import { Company } from "src/app/shared/models/company.model";
import * as _ from "lodash";
import { DeviceAssignModalComponent } from "../device-assign-modal/device-assign-modal.component";
import { Device } from "src/app/shared/models/device.model";
import { actionsExecuting, ActionsExecutingStateModel } from "src/app/core/state-management/ngxs/actions-executing";

@Component({
  selector: "device-assignment",
  templateUrl: "./device-assignment.component.html",
  styleUrls: ["./device-assignment.component.scss"],
})
export class DeviceAssignmentComponent implements OnInit, OnDestroy {
  //  @Select(DeviceState) devicesModel$: Observable<DeviceStateModel>;
  devicesModel$: Observable<DeviceStateModel>;
  //  @Select(actionsExecuting([FetchDevicesAssignments])) fetching$: Observable<ActionsExecutingStateModel>;
  fetching$: Observable<ActionsExecutingStateModel>;

  showingAssigned = false; // Whether to show assigned devices

  private onDestroy$ = new Subject();

  // Create behavior subject that has an empty map in it.
  // This allows companyList$ to be referenced before the data has been fetched
  companyList$ = new BehaviorSubject<{ [index: string]: string }>({});

  constructor(
    private readonly store: Store,
    public readonly userService: UserService,
    private readonly http: HttpClient,
    private readonly envService: EnvService,
    private readonly modalService: NgbModal
  ) {}

  ngOnInit() {
    this.devicesModel$ = this.store.select(DeviceState);
    this.fetching$ = this.store.select(actionsExecuting([FetchDevicesAssignments]));

    // Fill in companyList$
    this.http
      .get<Company[]>(`${this.envService.apiUri}/api/v1/company`)
      .pipe(
        startWith([]),
        map((it) => _.mapValues(_.keyBy(it, "id"), "name"))
      )
      .subscribe((it) => this.companyList$.next(it));

    this.store.dispatch([new FetchDevicesAssignments(this.showingAssigned)]);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // Hide or show hidden devices based on what the user selects
  // hide: boolean determining whether to hide or show. true = hide, false = show
  hideDevices(hide: boolean) {
    this.showingAssigned = !hide;
    this.store.dispatch([new FetchDevicesAssignments(this.showingAssigned)]);
  }

  openModal(device: Device, companyID: string) {
    const modalRef = this.modalService.open(DeviceAssignModalComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
    });

    modalRef.componentInstance.device = device;
    modalRef.componentInstance.assignedCompany = companyID;
  }
}
