import { UserClaims } from "../user-claims.enum";
import { UserRole } from "../user-role.type";

export class AppUser {
  id: string;
  azureId: string;
  userName: string;
  fullName: string;
  dateAdded: Date;
  dateLastOnline: Date;
  userRole: UserRole;
  photoData: string;
  userClaims: string;
  categories: string; // categories to which the user is subscribed

  // User claim handlers
  get claims(): string[] {
    return this.userClaims != null ? this.userClaims.split(", ").filter((it) => it !== "") : [];
  }

  set claims(claims: string[]) {
    this.userClaims = claims.filter((it) => it != null && it !== "").join(", ");
  }

  hasClaim(claim: string): boolean {
    return this.claims.includes(claim);
  }

  public addClaim(claim: string) {
    this.claims = [...this.claims, claim];
  }

  removeClaim(claim: string) {
    var index = this.claims.indexOf(claim);
    if (index >= 0) {
      var claims = [...this.claims];
      claims.splice(index, 1);

      this.claims = claims;
    }
  }

  // Console access handlers
  set hasConsoleAccess(hasAccess: boolean) {
    // UserClaims[UserClaims.BaseConsole] Evulates to the ToString()ish equivalent--i.e. to "BaseConsole"
    var consoleClaim = UserClaims[UserClaims.BaseConsole];
    if (hasAccess && !this.hasClaim(consoleClaim)) {
      this.addClaim(consoleClaim);
    } else if (!hasAccess) {
      this.removeClaim(consoleClaim);
    }
  }

  get hasConsoleAccess(): boolean {
    return this.hasClaim(UserClaims[UserClaims.BaseConsole]);
  }

  // Evaluate whether the user has one of the roles qualifying as an administrator
  // Used for when there isn't a specific claim to restrict access and we just want to restrict to admins generally.
  isAdmin(): boolean {
    return this.userRole === "ITAdmin" || this.userRole === "AstronicsDeveloper";
  }
}
