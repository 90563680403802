import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm, Validators, FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { MsalInterceptorConfiguration, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { ActionsExecuting, actionsExecuting } from "src/app/core/state-management/ngxs/actions-executing";
import {
  Actions,
  ofActionCompleted,
  ofActionDispatched,
  ofActionErrored,
  ofActionSuccessful,
  Select,
  Store,
} from "@ngxs/store";
import * as _ from "lodash";
import { combineLatest, forkJoin, fromEvent, Observable, of, Subject } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { SetDefaultAccess } from "src/app/core/state-management/company.state";
import { CreateUser, DeleteUser, SaveUser } from "src/app/core/state-management/user.state";
import { AzureUser, UserService } from "src/app/core/user.service";
import Swal from "sweetalert2";
import { AppUser } from "src/app/shared/models/user.model";

type azureResult = { value: AzureUser[] };

interface LoginForm {
  userName: FormControl<string>;
  name: FormControl<string>;
  role: FormControl<string>;
  id: FormControl<string>;
}

@Component({
  selector: "users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit, OnDestroy {
  @ViewChild("userInput", { static: false }) userInput: ElementRef;
  @ViewChild("generalForm", { static: false }) generalForm: NgForm;

  //  @Select(actionsExecuting([CreateUser])) creating$: Observable<ActionsExecuting>;
  creating$: Observable<ActionsExecuting>;
  //  @Select(actionsExecuting([SaveUser])) savingUser$: Observable<ActionsExecuting>;
  savingUser$: Observable<ActionsExecuting>;
  //  @Select(actionsExecuting([DeleteUser])) deletingUser$: Observable<ActionsExecuting>;
  deletingUser$: Observable<ActionsExecuting>;
  //  @Select(actionsExecuting([SetDefaultAccess])) settingAccess$: Observable<ActionsExecuting>;
  settingAccess$: Observable<ActionsExecuting>;

  private onDestroy$ = new Subject();

  newUserForm: FormGroup<LoginForm>;
  saving: { [key: string]: boolean };
  searching = false;

  companyUsers$: Observable<AppUser[]>;

  constructor(
    private readonly http: HttpClient,
    public readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly injector: Injector
  ) {
    const intConfig = this.injector.get<MsalInterceptorConfiguration>(MSAL_INTERCEPTOR_CONFIG);

    this.userService.companyUsers$.pipe(take(1)).subscribe((users) => {
      users.forEach((user) =>
        intConfig.protectedResourceMap.set(`https://graph.microsoft.com/beta/users/${user.userName}`, [
          "User.ReadBasic.All",
        ])
      );
    });

    this.companyUsers$ = this.userService.companyUsers$.pipe(
      mergeMap((users) => {
        const httpCalls = users.map((user) =>
          this.http
            .get(`https://graph.microsoft.com/v1.0/users/${user.azureId}/photo/$value`, {
              responseType: "blob",
            })
            .pipe(catchError(() => of(null)))
        );

        return forkJoin(httpCalls);
      }),
      withLatestFrom(this.userService.companyUsers$),
      tap(([photos, users]) => {
        const zipped = _.zip(users, photos);
        zipped.forEach(async ([user, photo]) => {
          const reader = new FileReader();

          if (photo != null) {
            reader.readAsDataURL(photo);

            await fromEvent(reader, "load")
              .pipe(
                tap(() => {
                  user.photoData = reader.result as string;
                })
              )
              .toPromise();
          }
        });
      }),
      map(([photos, users]) => users.sort((a, b) => (a.fullName > b.fullName ? 1 : -1))),
      tap((users) => {
        this.saving = _.chain(users)
          .keyBy((it) => it.id)
          .mapValues(() => false)
          .value();
      })
    );
  }

  ngOnInit() {
    this.creating$ = this.store.select(actionsExecuting([CreateUser]));
    this.savingUser$ = this.store.select(actionsExecuting([SaveUser]));
    this.deletingUser$ = this.store.select(actionsExecuting([DeleteUser]));
    this.settingAccess$ = this.store.select(actionsExecuting([SetDefaultAccess]));

    this.resetForm();

    this.actions$
      .pipe(
        ofActionDispatched(SaveUser),
        tap((action: SaveUser) => (this.saving[action.id] = true)),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    this.actions$
      .pipe(
        ofActionCompleted(SaveUser),
        tap(() => (this.saving = _.mapValues(this.saving, () => false))),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    this.actions$
      .pipe(
        ofActionSuccessful(SetDefaultAccess),
        tap(() => this.generalForm.form.markAsPristine()),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    this.actions$
      .pipe(
        ofActionSuccessful(CreateUser),
        tap(() => this.resetForm()),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  search = (text$: Observable<string>) => {
    return combineLatest([
      text$,
      this.userService.companyUsers$.pipe(map((it) => it.map((user) => user.fullName))),
    ]).pipe(
      debounceTime(200),
      distinctUntilChanged((a, b) => a[0] === b[0]),
      tap(() => (this.searching = true)),
      switchMap(([term, existingNames]) => (term.length < 3 ? of([]) : this.getAzureUsersNames(term, existingNames))),
      tap(() => (this.searching = false))
    );
  };

  userSelected(e: NgbTypeaheadSelectItemEvent) {
    this.newUserForm.get("id").setValue(e.item.id);
    this.newUserForm.get("name").setValue(e.item.displayName);
    this.newUserForm.get("userName").setValue(e.item.userPrincipalName);
  }

  getUserName(user: AzureUser) {
    return user.displayName;
  }

  createUser() {
    const { id, userName, name, role } = this.newUserForm.value;
    this.store.dispatch(new CreateUser(id, name, userName, role));
  }

  saveUser(id: string, role: string, claims: string) {
    this.store.dispatch(new SaveUser(id, role, claims));
  }

  deleteUser(id: string) {
    Swal.fire({
      title: "Are you sure?",
      html: `This will remove the user from the webRDM system.<br><br><i>*Note: If your company's default access setting
      is set to anything other than NoAccess, the user will be able to re-join webRDM at any time. 
      To remove their access entirely, set their role to 'No Access'</i> `,
      icon: "warning",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.store.dispatch(new DeleteUser(id));

        return new Promise((resolve, reject) => {
          this.actions$
            .pipe(
              ofActionSuccessful(DeleteUser),
              tap(() => resolve(null)),
              take(1)
            )
            .subscribe();

          this.actions$
            .pipe(
              ofActionErrored(DeleteUser),
              tap(() => {
                reject("Error deleting user");
              }),
              take(1)
            )
            .subscribe();
        }).catch((error) => {
          Swal.showValidationMessage(error);
        });
      },
    });
  }

  saveDefaultAccess(role: string) {
    this.store.dispatch(new SetDefaultAccess(role));
  }

  private resetForm() {
    if (this.userInput != null) {
      this.userInput.nativeElement.value = null;
    }

    this.newUserForm = this.formBuilder.group({
      userName: ["", Validators.required],
      name: ["", Validators.required],
      role: ["", Validators.required],
      id: ["", Validators.required],
    });

    this.newUserForm.markAsPristine();
  }

  private getAzureUsersNames = (term: string, existingNames: string[]) =>
    this.http
      .get<azureResult>(
        `https://graph.microsoft.com/v1.0/users?$select=id,givenName,surname,displayName,userPrincipalName&$filter=startsWith(givenName, '${term}') or startsWith(surname, '${term}')`
      )
      .pipe(
        map((it) => it.value),
        map((it) => it.filter((user) => !existingNames.includes(user.displayName)))
      );
}
