import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Navigate } from "@ngxs/router-plugin";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { insertItem, patch, removeItem, updateItem } from "@ngxs/store/operators";
import * as moment from "moment";
import { of } from "rxjs";
import { catchError, finalize, switchMapTo, tap } from "rxjs/operators";
import { Device } from "src/app/shared/models/device.model";
import Swal from "sweetalert2";
import * as _ from "underscore";
import { ApplicationInsightsService } from "../application-insights.service";
import { EnvService } from "../env.service";

export class FetchDevices {
  static readonly type = "[Devices] Fetch";

  constructor(public showHidden: boolean) {}
}

export class FetchDevicesAssignments {
  static readonly type = "[Devices] Fetch Assignments";
  constructor(public showAssigned: boolean) {}
}

export class FetchDeviceStatuses {
  static readonly type = "[Devices] Fetch Statuses";
  constructor(public showHidden: boolean) {}
}

export class FetchDevice {
  static readonly type = "[Devices] Fetch Single";

  constructor(public deviceId: string) {}
}

export class DeleteDevice {
  static readonly type = "[Devices] DeleteDevice]";
  constructor(public readonly device: Device) {}
}

export class AddDevice {
  static readonly type = "[Devices] AddDevice]";
  constructor(public readonly device: Partial<Device>) {}
}

export class RebootDevice {
  static readonly type = "[Devices] Reboot]";
  constructor(public readonly deviceId: string) {}
}

export class SetDeviceTime {
  static readonly type = "[Devices] Set Time]";
  constructor(public readonly deviceId: string) {}
}

export class RequestLogs {
  static readonly type = "[Devices] RequestLogs]";
  constructor(public readonly deviceId: string) {}
}

export class UpdateDevice {
  static readonly type = "[Devices] UpdateDevice]";
  constructor(public readonly device: Device) {}
}

export class UpdateDeviceAdmin {
  static readonly type = "[Devices] UpdateDevicedmin]";
  constructor(public readonly device: Device) {}
}

export interface DeviceStateModel {
  rebooting: _.Dictionary<boolean>;
  requestingLog: _.Dictionary<boolean>;
  data: Device[];
}

@Injectable()
@State<DeviceStateModel>({
  name: "devices",
  defaults: {
    rebooting: {},
    requestingLog: {},
    data: [],
  },
})
export class DeviceState {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvService,
    private readonly appInsights: ApplicationInsightsService
  ) {}

  @Selector()
  static lookupById(state: DeviceStateModel) {
    return _.indexBy(state.data, (it) => it.id);
  }

  @Action(FetchDevices)
  fetchDevices(ctx: StateContext<DeviceStateModel>, action: FetchDevices) {
    return this.http.get<Device[]>(`${this.envService.apiUri}/api/v1/devices?showHidden=${action.showHidden}`).pipe(
      tap((devices) => {
        devices.forEach((device) => {
          if (device.health != null) {
            device.health.timeSent = new Date(device.health.timeSent);
          }
        });

        ctx.setState(
          patch({
            data: devices.map((it) => Object.assign(new Device(), it)),
          })
        );
      })
    );
  }

  @Action(FetchDevicesAssignments)
  fetchDevicesAssignments(ctx: StateContext<DeviceStateModel>, action: FetchDevicesAssignments) {
    return this.http
      .get<Device[]>(`${this.envService.apiUri}/api/v1/devices/assignments?showAssigned=${action.showAssigned}`)
      .pipe(
        tap((devices) => {
          devices.forEach((device) => {
            if (device.health != null) {
              device.health.timeSent = new Date(device.health.timeSent);
            }
          });

          ctx.setState(
            patch({
              data: devices.map((it) => Object.assign(new Device(), it)),
            })
          );
        })
      );
  }

  @Action(FetchDeviceStatuses)
  fetchDeviceStatuses(ctx: StateContext<DeviceStateModel>, action: FetchDeviceStatuses) {
    return this.http
      .get<Device[]>(`${this.envService.apiUri}/api/v1/devices/status?showHidden=${action.showHidden}`)
      .pipe(
        tap((devices) => {
          const state = ctx.getState();
          const updatedDevices = devices.map((device) => {
            const updatedDevice = { ...state.data.find((it) => it.id === device.id) };
            if (updatedDevice != null) {
              updatedDevice.isOnline = device.isOnline;
              updatedDevice.lastConnected = device.lastConnected;
              updatedDevice.lastUpdated = device.lastUpdated;
            }

            return updatedDevice;
          });

          ctx.setState(
            patch({
              data: updatedDevices,
            })
          );
        })
      );
  }

  @Action(FetchDevice)
  fetchDevice(ctx: StateContext<DeviceStateModel>, action: FetchDevice) {
    return this.http
      .get<Device>(`${this.envService.apiUri}/api/v1/devices/${action.deviceId}`)
      .pipe(tap((device) => ctx.setState(patch({ data: insertItem<Device>(Object.assign(new Device(), device)) }))));
  }

  @Action(DeleteDevice)
  deleteDevice(ctx: StateContext<DeviceStateModel>, action: DeleteDevice) {
    this.appInsights.trackEvent({
      name: "Device delete",
    });

    return this.http.delete(`${this.envService.apiUri}/api/v1/devices/${action.device.id}`).pipe(
      tap(() => {
        ctx.setState(patch({ data: removeItem<Device>(({ id }) => id === action.device.id) }));
        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          title: `Successfully deleted device with serial number: ${action.device.serialNumber}`,
        });
      })
    );
  }

  @Action(AddDevice)
  addDevice(ctx: StateContext<DeviceStateModel>, action: AddDevice) {
    this.appInsights.trackEvent({
      name: "Device add",
    });

    return this.http.post<Device>(`${this.envService.apiUri}/api/v1/devices`, action.device).pipe(
      catchError((it) => {
        Swal.fire({
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          title: it.error.message,
        });

        return of(it);
      }),
      tap((result) => {
        if (result.error) {
          return result;
        }

        ctx.setState(patch({ data: insertItem<Device>(Object.assign(new Device(), result)) }));

        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          title: "Successfully added new device.",
        });

        ctx.dispatch(new Navigate(["/devices"]));
      })
    );
  }

  @Action(RebootDevice)
  rebootDevice(ctx: StateContext<DeviceStateModel>, action: RebootDevice) {
    this.appInsights.trackEvent({
      name: "Device reboot",
    });

    return of(true).pipe(
      tap((rebooting) => {
        const state = { rebooting: {} } as DeviceStateModel;
        state.rebooting[action.deviceId] = rebooting;

        ctx.setState(patch(state));
      }),
      switchMapTo(
        this.http.post<string>(
          `${this.envService.apiUri}/api/v1/console/reboot`,
          {
            deviceId: action.deviceId,
          },
          {
            headers: new HttpHeaders({
              Accept: "text/html",
            }),
            responseType: "text" as "json",
          }
        )
      ),
      tap(() =>
        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          title: "Reboot command has been sent to the device",
        })
      ),
      finalize(() => {
        const state = { rebooting: {} } as DeviceStateModel;
        state.rebooting[action.deviceId] = false;

        ctx.setState(patch(state));
      })
    );
  }

  @Action(SetDeviceTime)
  setDeviceTime(ctx: StateContext<DeviceStateModel>, action: SetDeviceTime) {
    this.appInsights.trackEvent({
      name: "Device timeSync",
    });

    return this.http
      .post<string>(`${this.envService.apiUri}/api/v1/console/setTime`, {
        deviceId: action.deviceId,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          Swal.fire({
            icon: "error",
            toast: true,
            position: "top-end",
            timer: 5000,
            title: err.error || "An unexpected error has occurred.",
          });

          return of(null);
        }),
        tap((setDate) => {
          if (setDate != null) {
            Swal.fire({
              icon: "success",
              toast: true,
              position: "top-end",
              timer: 3000,
              title: `Device date/time has been set to ${moment.utc(setDate).format("MM/DD/YYYY HH:mm:ss")}`,
            });
          }
        })
      );
  }

  @Action(RequestLogs)
  requestLogs(ctx: StateContext<DeviceStateModel>, action: RequestLogs) {
    this.appInsights.trackEvent({
      name: "Device requestLogs",
    });

    return of(true).pipe(
      tap((requesting) => {
        const state = { requestingLog: {} } as DeviceStateModel;
        state.requestingLog[action.deviceId] = requesting;

        ctx.setState(patch(state));
      }),
      switchMapTo(this.http.post(`${this.envService.apiUri}/api/v1/logrequest/device/${action.deviceId}`, "")),
      tap(() =>
        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          title: "Logs have been requested from device.",
        })
      ),
      catchError((err) => {
        Swal.fire({
          icon: "error",
          toast: true,
          position: "top-end",
          timer: 3000,
          title: "Unable to request logs from device.",
        });

        throw err;
      }),
      finalize(() => {
        const state = { requestingLog: {} } as DeviceStateModel;
        state.requestingLog[action.deviceId] = false;

        ctx.setState(patch(state));
      })
    );
  }

  // Update the device with the given guid to match properties of the given device
  @Action(UpdateDevice)
  updateDevice(ctx: StateContext<DeviceStateModel>, action: UpdateDevice) {
    return this.http.put(`${this.envService.apiUri}/api/v1/devices/${action.device.id}`, action.device).pipe(
      tap(() => {
        ctx.setState(
          patch({
            data: updateItem((it) => it.id === action.device.id, action.device),
          })
        );

        Swal.fire({
          icon: "success",
          toast: true,
          position: "top-end",
          timer: 3000,
          title: `Successfully updated device.`,
        });
      })
    );
  }

  // Update the device with the given guid to match properties of the given device. This is functionally the same as
  // update device, but is restricted to ballard/admin users. It also allows changing of company, whereas UpdateDevice
  // does not.
  @Action(UpdateDeviceAdmin)
  updateDeviceAdmin(ctx: StateContext<DeviceStateModel>, action: UpdateDeviceAdmin) {
    return this.http
      .put(`${this.envService.apiUri}/api/v1/devices/updateadmin/${action.device.id}`, action.device)
      .pipe(
        tap(() => {
          ctx.setState(
            patch({
              data: updateItem((it) => it.id === action.device.id, action.device),
            })
          );

          Swal.fire({
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            title: `Successfully updated device.`,
          });
        })
      );
  }
}
