import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { UserService } from "../user.service";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly ngRouter: Router,
    private readonly authService: AuthService,
    private readonly msalGuard: MsalGuard,
    private readonly userService: UserService
  ) {}

  canActivate(
    route?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot
  ): boolean | Promise<boolean> | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    const loggedIn = this.authService.isLoggedIn();
    if (!loggedIn) {
      return this.ngRouter.parseUrl("/auth/login");
    }

    let activated = this.msalGuard.canActivate(route, state);

    return activated.pipe(
      switchMap((isActivated) => {
        if (!isActivated) {
          if (route.routeConfig.path !== "/auth/login") {
            return of(null);
          }

          return of(this.ngRouter.parseUrl("/auth/login"));
        }

        return combineLatest([this.userService.company$, this.userService.user$]);
      }),
      map((result) => {
        if (result == null) {
          return this.ngRouter.parseUrl("/auth/login");
        }

        let [ten, user] = result;
        if (ten == null) {
          return this.ngRouter.parseUrl("/auth/error");
        } else if (!ten.enabled) {
          return this.ngRouter.parseUrl("/auth/registered");
        } else if (user.userRole === "NoAccess") {
          return this.ngRouter.parseUrl("/not-authorized");
        }

        return true;
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}
