import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { actionsExecuting, ActionsExecuting } from "src/app/core/state-management/ngxs/actions-executing";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { startWith, takeUntil, tap } from "rxjs/operators";
import {
  FetchLogSchedule,
  LogScheduleState,
  LogScheduleStateModel,
  SaveLogSchedule,
} from "src/app/core/state-management/log-schedule.state";
import { LogSchedule } from "../../../../shared/models/log-schedule.model";

interface LogScheduleForm {
  runScheduled: FormControl<boolean>;
  scheduledTime: FormControl<string>;
  runWeekly: FormControl<boolean>;
  dayToRun: FormControl<string>;
  webhookEnabled: FormControl<boolean>;
  webhookUrl: FormControl<string>;
  webhookSourceName: FormControl<string>;
  authenticateRequest: FormControl<boolean>;
  username: FormControl<string>;
  password: FormControl<string>;
}

@Component({
  selector: "log-schedule",
  templateUrl: "./log-schedule.component.html",
  styleUrls: ["./log-schedule.component.scss"],
})
export class LogScheduleComponent implements OnInit, OnDestroy {
  //  @Select(LogScheduleState) logScheduleState$: Observable<LogScheduleStateModel>;
  logScheduleState$: Observable<LogScheduleStateModel>;
  //  @Select(actionsExecuting([SaveLogSchedule])) saving$: Observable<ActionsExecuting>;
  saving$: Observable<ActionsExecuting>;

  private onDestroy$ = new Subject();

  hours = Array.apply(null, Array(24)).map((_it: number, i: number) => `${i.toString().padStart(2, "0")}:00`);
  days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  logScheduleForm = new FormGroup<LogScheduleForm>({
    runScheduled: new FormControl<boolean>(false),
    scheduledTime: new FormControl<string>(""),
    runWeekly: new FormControl<boolean>(false),
    dayToRun: new FormControl<string>(""),
    webhookEnabled: new FormControl<boolean>(false),
    webhookUrl: new FormControl<string>("", Validators.required),
    webhookSourceName: new FormControl<string>("", Validators.required),
    authenticateRequest: new FormControl<boolean>(false),
    username: new FormControl<string>("", Validators.required),
    password: new FormControl<string>("", Validators.required),
  });

  schedule = new LogSchedule();

  constructor(private readonly store: Store) {}

  get scheduleEnabled(): boolean {
    return this.logScheduleForm.get("runScheduled").value;
  }

  get runWeekly(): boolean {
    return this.logScheduleForm.get("runWeekly").value;
  }

  get webhookEnabled(): boolean {
    return this.logScheduleForm.get("webhookEnabled").value;
  }

  get shouldAuthenticateRequest(): boolean {
    return this.logScheduleForm.get("authenticateRequest").value;
  }

  ngOnInit() {
    this.logScheduleState$ = this.store.select(LogScheduleState);
    this.saving$ = this.store.select(actionsExecuting([SaveLogSchedule]));

    this.monitorFormChanges();

    this.store.dispatch(new FetchLogSchedule());
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  saveScheduleChanges() {
    this.store.dispatch(new SaveLogSchedule());
  }

  private monitorFormChanges() {
    this.logScheduleForm
      .get("runScheduled")
      .valueChanges.pipe(
        startWith(false),
        tap((checked) => {
          if (checked) {
            this.logScheduleForm.get("runWeekly").enable();
            this.logScheduleForm.get("scheduledTime").enable();
          } else {
            this.logScheduleForm.get("scheduledTime").disable();
            this.logScheduleForm.get("runWeekly").disable();
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    this.logScheduleForm
      .get("runWeekly")
      .valueChanges.pipe(
        startWith(false),
        tap((checked) => {
          if (checked && !this.logScheduleForm.get("runWeekly").disabled) {
            this.logScheduleForm.get("dayToRun").enable();
          } else {
            this.logScheduleForm.get("dayToRun").disable();
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    this.logScheduleForm
      .get("webhookEnabled")
      .valueChanges.pipe(
        startWith(false),
        tap((checked) => {
          if (checked) {
            this.logScheduleForm.get("webhookUrl").enable();
            this.logScheduleForm.get("webhookSourceName").enable();
            this.logScheduleForm.get("authenticateRequest").enable();
          } else {
            this.logScheduleForm.get("webhookUrl").disable();
            this.logScheduleForm.get("webhookSourceName").disable();
            this.logScheduleForm.get("authenticateRequest").disable();
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    this.logScheduleForm
      .get("authenticateRequest")
      .valueChanges.pipe(
        startWith(false),
        tap((checked) => {
          if (checked && !this.logScheduleForm.get("authenticateRequest").disabled) {
            this.logScheduleForm.get("username").enable();
            this.logScheduleForm.get("password").enable();
          } else {
            this.logScheduleForm.get("username").disable();
            this.logScheduleForm.get("password").disable();
          }
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }
}
