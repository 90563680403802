import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { MsalRedirectComponent } from "@azure/msal-angular";
import { NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "./app-component/app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { TopNavComponent } from "./layouts/components/top-nav/top-nav.component";
import { ContentLayoutComponent } from "./layouts/content-layout/content-layout.component";
import { SharedModule } from "./shared/shared.module";
import { MonacoEditorModule, NgxMonacoEditorConfig } from "ngx-monaco-editor-v2";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NotificationsModalComponent } from "./layouts/components/notifications-modal/notifications-modal.component";
import { NgxsModule } from "@ngxs/store";
import { BasicNotificationState } from "./core/state-management/basic-notification.state";

const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: "./assets",
  onMonacoLoad: () => {},
};

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    ContentLayoutComponent,
    TopNavComponent,
    NotificationsModalComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    CommonModule,
    SharedModule,
    AppRoutingModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgxsModule.forFeature([BasicNotificationState]),
    FormsModule,
    MonacoEditorModule.forRoot(monacoConfig),
  ],
  providers: [],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
