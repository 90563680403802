import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Select, Store } from "@ngxs/store";
import { forkJoin, fromEvent, Observable, of, Subject, Subscription, timer } from "rxjs";
import { catchError, map, switchMap, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import {
  BasicNotificationState,
  BasicNotificationStateModel,
  FetchNotifications,
} from "src/app/core/state-management/basic-notification.state";
import { UserService } from "src/app/core/user.service";
import { BasicNotification } from "src/app/shared/models/basic-notification.model";
import { Dictionary } from "underscore";
import { NotificationsModalComponent } from "../notifications-modal/notifications-modal.component";
import { UserClaims } from "src/app/shared/user-claims.enum";

interface MsUser {
  id: string;
  name: string;
  email: string;
  photoData: string;
}

@Component({
  selector: "top-nav",
  templateUrl: "./top-nav.component.html",
  styleUrls: ["./top-nav.component.scss"],
})
export class TopNavComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();

  user$: Observable<MsUser>;
  //  @Select(BasicNotificationState) notifState$: Observable<BasicNotificationStateModel>;
  notifState$: Observable<BasicNotificationStateModel>;
  unreadNotifs$: Observable<BasicNotification[]>;
  readMap$: Observable<Dictionary<boolean>>;

  // # of unread notifications
  unreadNotificationCt: number;

  claims = UserClaims;

  constructor(
    private readonly http: HttpClient,
    public readonly userService: UserService,
    private readonly msalService: MsalService,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly store: Store
  ) {}

  ngOnInit() {
    this.notifState$ = this.store.select(BasicNotificationState);

    const reader = new FileReader();

    this.user$ = forkJoin([
      this.http.get<{ displayName: string; mail: string }>("https://graph.microsoft.com/v1.0/me"),
      this.http
        .get("https://graph.microsoft.com/v1.0/me/photo/$value", {
          responseType: "blob",
        })
        .pipe(catchError(() => of(null))),
    ]).pipe(
      withLatestFrom(this.userService.user$),
      switchMap(([[user, photo], dbUser]) => {
        const msUser = {
          id: dbUser.id,
          name: user.displayName,
          email: user.mail,
        } as MsUser;

        if (!photo) {
          return of(msUser);
        }

        reader.readAsDataURL(photo);

        return fromEvent(reader, "load").pipe(
          map(() => {
            return { ...msUser, photoData: reader.result, role: dbUser.userRole } as MsUser;
          })
        );
      })
    );

    // fetch notifications for current user, checks every 60 seconds
    this.user$
      .pipe(
        switchMap((user) =>
          timer(0, 60000).pipe(
            tap(() => this.store.dispatch(new FetchNotifications(user.id))),
            takeUntil(this.onDestroy$)
          )
        )
      )
      .subscribe();

    this.readMap$ = this.notifState$.pipe(map((it) => it.read));

    // Filter down to unread and unarchived
    this.unreadNotifs$ = this.notifState$.pipe(
      map((it) => {
        const readMap = it.read;

        return it.data.filter((notif) => !readMap[notif.id] && notif.dateResolved === null);
      })
    );

    // get count of result
    this.unreadNotifs$.subscribe((data) => (this.unreadNotificationCt = data.length));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async openNotifications(userId: string) {
    const modalRef = this.modalService.open(NotificationsModalComponent, {
      backdrop: true,
      keyboard: true,
      size: "xl",
      scrollable: true,
    });

    modalRef.componentInstance.userId = userId;
    modalRef.componentInstance.notifState$ = this.notifState$;
  }

  logout() {
    this.msalService.logout();
  }

  help() {
    this.router.navigateByUrl("/help");
  }

  settings() {
    this.router.navigateByUrl("/settings");
  }

  adminSettings() {
    this.router.navigateByUrl("/admin");
  }
}
