import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Actions, ofActionDispatched, Store } from "@ngxs/store";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { catchError, map, shareReplay, switchMapTo, takeUntil, tap } from "rxjs/operators";
import { Company } from "../shared/models/company.model";
import { AppUser } from "../shared/models/user.model";
import { EnvService } from "./env.service";
import { UpdateUserList } from "./state-management/user.state";

export type AzureUser = {
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  userPrincipalName: string;
};

@Injectable()
export class UserService implements OnDestroy {
  private onDestroy$ = new Subject();

  // can call refreshUsers$.next() to force the companyUsers$ to fetch fresh data from the server
  private refreshUsers$ = new BehaviorSubject<void>(undefined);

  // can call refreshCompany$.next() to force the company$ to fetch fresh data from the server
  private refreshCompany$ = new BehaviorSubject<void>(undefined);

  // observable representing the currently signed in user
  user$: Observable<AppUser>;

  // observable representing a list of users saved to the database
  companyUsers$: Observable<AppUser[]>;

  // observable representing the company of the signed in user
  company$: Observable<Company>;

  constructor(private readonly http: HttpClient, private readonly envService: EnvService, actions: Actions) {
    actions
      .pipe(
        ofActionDispatched(UpdateUserList),
        tap(() => this.refreshUserList()),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    this.company$ = this.http.get<Company>(`${this.envService.apiUri}/api/v1/company/getTenant`).pipe(
      tap((it) => {
        if (it != null) {
          it.dateEnabled = new Date(it.dateEnabled);
          it.dateRegistered = new Date(it.dateRegistered);
        }
      }),
      catchError(() => of(null as Company)),
      shareReplay(1)
    );

    this.user$ = this.http.get<AppUser>(`${this.envService.apiUri}/api/v1/users/me`).pipe(
      map((it) => {
        var user = Object.assign(new AppUser(), it);
        user.dateAdded = new Date(it.dateAdded);
        user.dateLastOnline = new Date(it.dateLastOnline);
        return user;
      }),
      shareReplay(1)
    );

    this.companyUsers$ = this.refreshUsers$.pipe(
      switchMapTo(this.http.get<AppUser[]>(`${this.envService.apiUri}/api/v1/users`)),
      map((users) => {
        return users.map((it) => {
          var user = Object.assign(new AppUser(), it);
          user.dateAdded = new Date(it.dateAdded);
          user.dateLastOnline = new Date(it.dateLastOnline);
          return user;
        });
      }),
      shareReplay(1, 20)
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  refreshUserList() {
    this.refreshUsers$.next();
  }

  refreshCompany() {
    this.company$ = this.http.get<Company>(`${this.envService.apiUri}/api/v1/company/getTenant`).pipe(
      tap((it) => {
        if (it != null) {
          it.dateEnabled = new Date(it.dateEnabled);
          it.dateRegistered = new Date(it.dateRegistered);
        }
      }),
      catchError(() => of(null as Company)),
      shareReplay(1)
    );
  }

  // Evaluate whether the user has a specific UserClaim
  // Marking as async and a Promise return val because otherwise the return of thisd was
  // being evaluated BEFORE it returned--a promise return guarantees a return value so the code knows to wait.
  async hasClaim(claim: string): Promise<boolean> {
    var hasClaim = false;
    await this.user$.subscribe((value) => {
      hasClaim = value.userClaims.includes(claim);
    });
    return hasClaim;
  }

  async hasCategory(cat: string): Promise<boolean> {
    var hasCat = false;
    await this.user$.subscribe((value) => {
      hasCat = value.categories.includes(cat);
    });
    return hasCat;
  }
}
