import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveEnd, Router } from "@angular/router";
import { ApplicationInsights, IPageViewTelemetry } from "@microsoft/applicationinsights-web";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { Company } from "../shared/models/company.model";
import { AppUser } from "../shared/models/user.model";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class ApplicationInsightsService implements OnDestroy {
  private onDestroy$ = new Subject();

  private appInsights: ApplicationInsights;
  trackEvent: any;

  constructor(private readonly router: Router, private readonly envService: EnvService) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.envService.instrumentationKey,
      },
    });

    this.appInsights.loadAppInsights();
    this.trackEvent = this.appInsights.trackEvent;

    // Hooks into the Angular router to detect page changes, and then tracks them with app insights
    this.router.events
      .pipe(
        filter((event) => event instanceof ResolveEnd),
        takeUntil(this.onDestroy$)
      )
      .subscribe((event: ResolveEnd) => {
        this.logPageView(`Navigate ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
      });
  }

  // Just connect the trackEvent method directly into this service.
  // trackEvent = this.appInsights.trackEvent;

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  // sets the user's context so all telemetry events contain the identifying information
  setUserContext(user: AppUser, company: Company) {
    this.appInsights.setAuthenticatedUserContext(user.id, company.id);

    this.appInsights.addTelemetryInitializer((item) => {
      item.data["UserId"] = user.id;
      item.data["CompanyId"] = company.id;
      item.data["Email"] = user.userName;
      item.data["FullName"] = user.fullName;
      item.data["CompanyName"] = company.name;
    });
  }

  // clears the user's context
  clearUserContext() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  // explicitly logs a page view
  logPageView(name: string, uri: string) {
    var tel = { name, uri } as IPageViewTelemetry;

    this.appInsights.trackPageView(tel);
  }

  // returns the activated route as determine by the paths in the routing modules
  // ie .. /device/:id is the route for looking at a single device
  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = "";
    if (snapshot.routeConfig) {
      path += `${!path.startsWith("/") && snapshot.routeConfig.path !== "" ? "/" : ""}${snapshot.routeConfig.path}`;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
