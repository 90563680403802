import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Navigate } from "@ngxs/router-plugin";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { tap } from "rxjs/operators";
import { Deployment } from "src/app/shared/models/deployment.model";
import { Device } from "src/app/shared/models/device.model";
import Swal from "sweetalert2";
import * as _ from "underscore";
import { ApplicationInsightsService } from "../application-insights.service";
import { EnvService } from "../env.service";

export class CreateDeployment {
  static readonly type = "[Deployment] CreateDeployment]";
  constructor(
    public readonly name: string,
    public readonly dateEffective: Date,
    public readonly catalog: File,
    public readonly collectionId: string,
    public readonly catalogId?: string
  ) {}
}

export class FetchDeployments {
  static readonly type = "[Deployment] FetchDeployments]";
}

export class FetchCollectionDeployments {
  static readonly type = "[Deployment] FetchCollectionDeployments]";

  constructor(public readonly collectionId: string) {}
}

export class RetryDeployment {
  static readonly type = "[Deployment] Retry";

  constructor(public readonly deployment: Deployment, public readonly device: Device) {}
}

export interface DeploymentStateModel {
  data: {
    [index: string]: Deployment[];
  };
}

@Injectable()
@State<DeploymentStateModel>({
  name: "deployments",
  defaults: {
    data: {},
  },
})
export class DeploymentState {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvService,
    private readonly appInsights: ApplicationInsightsService
  ) {}

  @Selector()
  static lookupById(state: DeploymentStateModel) {
    return _.indexBy(state.data.all, (it) => it.id);
  }

  @Selector()
  static lookupByTypeAndId(state: DeploymentStateModel) {
    return (type: string) => {
      return _.indexBy(state.data[type], (it) => it.id);
    };
  }

  @Action(CreateDeployment)
  createDeployment(ctx: StateContext<DeploymentStateModel>, action: CreateDeployment) {
    const data = new FormData();
    data.append("catalog", action.catalog);
    data.append("name", action.name);
    data.append("dateEffective", action.dateEffective.toISOString());
    data.append("collectionId", action.collectionId);

    if (action.catalogId != null) {
      data.append("catalogId", action.catalogId);
    }

    this.appInsights.trackEvent({
      name: "Deployment create",
    });

    return this.http
      .post<Deployment>(`${this.envService.apiUri}/api/v1/deployments`, data, {
        headers: {
          encType: "multipart/form-data",
        },
      })
      .pipe(
        tap((result) => {
          const oldState = ctx.getState();
          ctx.setState(
            patch({
              data: {
                all: [...(oldState.data.all || []), result],
              },
            } as unknown as DeploymentStateModel)
          );

          Swal.fire({
            icon: "success",
            toast: true,
            position: "top-end",
            timer: 3000,
            title: "Deployment created.",
          });

          ctx.dispatch(new Navigate(["/deployments"]));
        })
      );
  }

  @Action(FetchDeployments)
  fetchDeployments(ctx: StateContext<DeploymentStateModel>) {
    return this.http.get<Deployment[]>(`${this.envService.apiUri}/api/v1/deployments`).pipe(
      tap((result) =>
        ctx.setState(
          patch({
            data: {
              all: result.map((it) => new Deployment(it)),
            },
          } as unknown as DeploymentStateModel)
        )
      )
    );
  }

  @Action(FetchCollectionDeployments)
  fetchCollectionDeployments(ctx: StateContext<DeploymentStateModel>, action: FetchCollectionDeployments) {
    return this.http
      .get<Deployment[]>(`${this.envService.apiUri}/api/v1/collections/${action.collectionId}/deployments`)
      .pipe(
        tap((result) =>
          ctx.setState(
            patch({
              data: {
                [action.collectionId]: result.map((it) => new Deployment(it)),
              },
            })
          )
        )
      );
  }

  @Action(RetryDeployment)
  retryDeployment(ctx: StateContext<DeploymentStateModel>, action: RetryDeployment) {
    this.appInsights.trackEvent({
      name: "Deployment retry",
    });

    return this.http.post(
      // eslint-disable-next-line max-len
      `${this.envService.apiUri}/api/v1/deployments/retry?deploymentId=${action.deployment.id}&deviceId=${action.device.id}`,
      {}
    );
  }
}
