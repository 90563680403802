import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { AuthService } from "src/app/core/auth.service";
import { UserService } from "src/app/core/user.service";
import { match, not } from "ts-pattern";

type AuthorizePaths = "register" | "authorize" | "terms" | "registered";

@Injectable({
  providedIn: "root",
})
export class AuthorizeGuard implements CanActivate {
  constructor(
    private readonly ngRouter: Router,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly msalService: MsalService,
    private readonly broadcastService: MsalBroadcastService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.broadcastService.inProgress$.pipe(
      // make sure no AD authentication stuff is in progress
      filter((status: InteractionStatus) => status == InteractionStatus.None),
      map(() => {
        // pass along the logged in user information to the switchMap
        let activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount) {
          if (this.msalService.instance.getAllAccounts().length > 0) {
            let accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
            return accounts[0];
          } else {
            return null;
          }
        }

        return activeAccount;
      }),
      switchMap((user) => {
        // grab the latest company and pass along with the user to the map
        this.userService.refreshCompany();
        return combineLatest([of(user), this.userService.company$]);
      }),
      map(([user, ten]) => {
        let matchObject = {
          user,
          ten,
          path: route.routeConfig.path,
        };

        let register = () => this.ngRouter.parseUrl("/auth/register");
        let registered = () => this.ngRouter.parseUrl("/auth/registered");
        let terms = () => this.ngRouter.parseUrl("/auth/terms");
        let authError = () => this.ngRouter.parseUrl("/auth/error");
        let login = () => this.ngRouter.parseUrl("/auth/login");
        let dashboard = () => this.ngRouter.parseUrl("/");
        let noRedirect = () => true;

        // pattern matching library - I feel like it's easier to read than a ton of if/else if statements
        return match(matchObject)
          .with({ user: null, path: "register" }, noRedirect)
          .with({ user: null, path: not("register") }, register)
          .with({ user: not(null), ten: null, path: "authorize" }, authError)
          .with({ user: not(null), ten: null, path: "register" }, terms)
          .with({ user: not(null), ten: null, path: "registered" }, login)
          .with({ user: not(null), ten: { enabled: false }, path: "authorize" }, registered)
          .with({ user: not(null), ten: { enabled: false }, path: "register" }, registered)
          .with({ user: not(null), ten: { enabled: false }, path: "terms" }, registered)
          .with({ user: not(null), ten: { enabled: false }, path: "registered" }, noRedirect)
          .with({ user: not(null), ten: { enabled: true } }, dashboard)
          .otherwise(noRedirect);
      })
    );
  }
}
