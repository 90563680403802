import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { NgxsModule } from "@ngxs/store";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";
import { EnvService } from "./env.service";
import { EnvServiceFactory } from "./env.service.provider";
import { AuthGuard } from "./guards/auth.guard";
import { throwIfAlreadyLoaded } from "./guards/module-import-guard";
import { LoadingStrategyService } from "./loading-strategy.service";
import { DeploymentState } from "./state-management/deployment.state";
import { DeviceState } from "./state-management/device.state";
import { CompanyState } from "./state-management/company.state";
import { UserService } from "./user.service";
import { RolesGuard } from "./guards/roles.guard";
import { NgxsActionsExecutingModule } from "./state-management/ngxs/actions-executing";

// Remove this line to use Angular Universal
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(envService: EnvService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: envService.clientId,
      authority: "https://login.microsoftonline.com/organizations",
      redirectUri: `${envService.hostUrl}/${
        window.location.pathname.endsWith("/register") ? "auth/terms" : "authorize"
      }`,
      postLogoutRedirectUri: envService.hostUrl,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(envService: EnvService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["User.Read"]);
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/organization", ["User.Read"]);
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/users", ["User.ReadBasic.All"]);
  protectedResourceMap.set(envService.apiUri, [`api://${envService.clientId}/User.Read/All`]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["User.Read"],
    },
  };
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forRoot([DeploymentState, DeviceState, CompanyState], { developmentMode: !environment.production }),
    NgxsRouterPluginModule.forRoot(),
    NgxsActionsExecutingModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    SweetAlert2Module.forRoot(),
    MsalModule,
  ],
  providers: [
    AuthGuard,
    RolesGuard,
    AuthService,
    UserService,
    CookieService,
    LoadingStrategyService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: (envService: EnvService) => MSALInstanceFactory(envService),
      deps: [EnvService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: (envService: EnvService) => MSALInterceptorConfigFactory(envService),
      deps: [EnvService],
    },
    {
      provide: EnvService,
      useFactory: EnvServiceFactory,
      deps: [],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
