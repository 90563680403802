import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class DevicePageService {
  private currentTab = "Devices";

  constructor(private readonly router: Router) {}

  getCurrentTab() {
    return this.currentTab;
  }

  setCurrentTab(tab: string) {
    this.currentTab = tab;
  }
}
