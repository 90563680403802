import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "webRDM";

  // Cookie policy vars used in cookie policy pop-up
  cookieMessage =
    "We use cookies to ensure that we give you the best experience on our website. View the Cookie Policy to learn more about the data we collect and how it is used. By continuing to use this website, you consent to our use of cookies.";
  cookieDismiss = "Got It!";
  cookieLinkText = "View Cookie Policy";
  cookiePolicyLink = "auth/cookie-policy";

  constructor() {}

  ngOnInit() {
    // Set up cookie policy notification with it's appearance and text
    // Shows up on every page if policy has not yet been acknowledged
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#002957", // Primary
        },
        button: {
          background: "#5383ad", // Primary light
          text: "#FFFFFF",
        },
      },
      theme: "classic",
      content: {
        message: this.cookieMessage,
        dismiss: this.cookieDismiss,
        link: this.cookieLinkText,
        href: this.cookiePolicyLink,
      },
    });
  }
}
